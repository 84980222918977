import React from 'react';
import FormSection from './FormSection';
import ResultSection from '../features/ResultSection';
import './CoreContent.css'
import {useSelector} from "react-redux";
import {
    LOADING_TYPE,
    selectLoadingState,
    selectRecommandationsResult,
    selectSearchResult
} from "../features/searchSlice";

export default function CoreContent(props){

    const searchResult = useSelector(selectSearchResult);
    const recommandationsResult = useSelector(selectRecommandationsResult);
    const loadingState = useSelector(selectLoadingState);

    let className = 'CoreContent';

    if(loadingState !== LOADING_TYPE.NONE || searchResult.length > 0 || recommandationsResult.length > 0) {
        className += ' upperPositionTransform'
    } else {
        className += ' defaultTransformY'
    }


    return (
        <div className={className}>
            <FormSection />
            <ResultSection />
        </div>
    )
}