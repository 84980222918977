import React from 'react';
import './Footer.css';
import GleephMinLogo from '../assets/GleephMin.svg'
import AppleLogo from '../assets/Apple.svg'
import AndroidLogo from '../assets/Android.svg'
import GleephScreenshot from '../assets/GleephScreenshot.png'
import {useSelector} from "react-redux";
import {selectRecommandationsResult} from "../features/searchSlice";
import StoreAndroid from "../assets/StoreAndroid.png";
import StoreApple from "../assets/StoreApple.png";
import {LOGS, sendLogToLeanplum} from "../utils";

export default function Footer(props) {
    const recommandationsResult = useSelector(selectRecommandationsResult);
    if(recommandationsResult && recommandationsResult.length === 0){
        return (
            <footer className={"FooterNoReco"}>
                <section className={"LeftPart"}>
                    <a href={'https://gleeph.net'} onClick={sendLogToLeanplum(LOGS.CLICK_GLEEPH_BUTTON)}><img src={GleephMinLogo} className={"button"} /></a>
                    <p>Téléchargez l’application pour gérer votre bibliothèque, recevoir des suggestions, et plus encore ! <a href={"https://gleeph.net/legal.html"}  className={"legalMention"}>Mentions légales</a></p>
                </section>
                <section className={"RightPart"}>
                    <a href={"https://apps.apple.com/fr/app/gleeph/id1193955842"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_MIN_BUTTON, {store: "APPSTORE"})}><img src={AppleLogo} className={"button"}/></a>
                    <div />
                    <a href={"https://play.google.com/store/apps/details?id=net.gleeph.hybridclient&hl=fr"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_MIN_BUTTON, {store: "PLAYSTORE"})}><img src={AndroidLogo} className={"button"}/></a>
                </section>
            </footer>
        )
    } else {
        return (
            <section className={"FooterWithReco"}>
                <p className={"textOnMobileFooter"}>Vous voulez des suggestions encore plus personnalisées ?</p>
                <section className={"FooterContent"}>
                    <img src={GleephScreenshot} />
                    <div>
                        <p>Vous voulez des suggestions encore plus personnalisées ?</p>
                        <p>Téléchargez Gleeph, l’application des lecteurs. <br/><br/>
                            Gérez votre bibliothèque, partagez vos coups  de cœur et recevez chaque jour de nouvelles suggestions de lecture !</p>
                        <div>
                            <a href={"https://apps.apple.com/fr/app/gleeph/id1193955842"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_FULL_BUTTON, {store: "APPSTORE"})}><img src={StoreApple}/></a>
                            <a href={"https://play.google.com/store/apps/details?id=net.gleeph.hybridclient&hl=fr"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_FULL_BUTTON, {store: "PLAYSTORE"})}><img src={StoreAndroid}/></a>
                        </div>
                    </div>
                </section>
                <footer className={"FooterNoReco"}>
                    <section className={"LeftPart"}>
                        <img src={GleephMinLogo} />
                        <p>Téléchargez l’application pour gérer votre bibliothèque, recevoir des suggestions, et plus encore !<a href={"https://gleeph.net/legal.html"}  className={"legalMention"}>Mentions légales</a></p>
                    </section>
                    <section className={"RightPart"}>
                        <a href={"https://apps.apple.com/fr/app/gleeph/id1193955842"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_MIN_BUTTON, {store: "APPSTORE"})}><img src={AppleLogo} /></a>
                        <div />
                        <a href={"https://play.google.com/store/apps/details?id=net.gleeph.hybridclient&hl=fr"} onClick={sendLogToLeanplum(LOGS.CLICK_STORE_MIN_BUTTON, {store: "PLAYSTORE"})}><img src={AndroidLogo} /></a>
                    </section>
                </footer>
            </section>
        )
    }

}