import React from 'react';
import './SearchResult.css';
import {fetchRecommandationResult} from "./searchSlice";
import {CST, LOGS, sendLogToLeanplum} from "../utils";
import {useDispatch} from "react-redux";
import DefaultCover from "../components/DefaultCover";


export default function SearchResult(props){
    const dispatch = useDispatch();
    function selectThisResult() {
        dispatch(fetchRecommandationResult(props));
        sendLogToLeanplum(LOGS.SELECT_BOOK_FROM_SEARCH)()
    }
    const pict = !!props.picture && !!props.picture.length > 0 && props.picture[0];
    const authors = props.authors instanceof Array
        ? props.authors.join(', ')
        : props.authors || '';
    return (
        <section className={"SearchResult"} onClick={selectThisResult}>
            {
                !!pict ? <img src={`${CST.S3_WORKS}/${pict}-300`} className={"shadowed"}/>
                :
                    <DefaultCover />
            }

            <h2 className={"title"}>{props.title}</h2>
            <h3 className={"author"}> {authors} • {props.publicationDate}</h3>
            <h3 className={"edition"}>{props.edition}</h3>
        </section>
    )

}