import React, { useState } from 'react';
import './ResultSection.css';
import SearchResult from "./SearchResult";
import { useSelector, useDispatch } from "react-redux";
import {
    selectSearchResult,
    selectRecommandationsResult, selectLoadingState,
    selectSelectedBook,
    selectTextToDisplay,
    cleanRecommandation,
    LOADING_TYPE,
    TEXT_TO_DISPLAY,
} from "./searchSlice";
import RecommandationResult from "./RecommandationResult";
import LoadingAnimation from "../components/LoadingAnimation";
import {CST, LOGS, sendLogToLeanplum} from "../utils";
import LeftArrow from '../assets/LeftArrow.svg'
import rightArrow from '../assets/RightArrow.svg'
import DefaultCover from "../components/DefaultCover";

export default function ResultSection(props) {
    const dispatch = useDispatch();
    const searchResult = useSelector(selectSearchResult);
    const recommandationsResult = useSelector(selectRecommandationsResult);
    const loadingState = useSelector(selectLoadingState);
    const selectedBook = useSelector(selectSelectedBook);
    const textToDisplay = useSelector(selectTextToDisplay)

    function renderSearchResult() {
        if(searchResult.length > 0 && recommandationsResult.length === 0){
            return searchResult.map((r) => {
                return <SearchResult key={r.id} id={r.id} title={r.title} picture={r.imgs} authors={r.authors} edition={r.edition} publicationDate={r.year_first} />
            })
        }
    }

    function renderRecommandationResult() {
        if(recommandationsResult.length > 0){
            return recommandationsResult.map((r) => {
                return <RecommandationResult key={r.id} id={r.id} title={r.title} picture={r.image} authors={r.authors} edition={r.edition} publicationDate={r.publicationYear} description={r.description}/>
            })
        }
    }

    function renderTitleText() {
        if(textToDisplay === TEXT_TO_DISPLAY.SEARCH_A_BOOK){
            return <p className={"informationText"}>Recherchez un livre que vous avez aimé pour trouver vos prochains coups de coeur !</p>
        }
        if(textToDisplay === TEXT_TO_DISPLAY.SELECT_A_BOOK){
            return <p className={"informationText"}>Sélectionnez le livre auquel vous pensiez</p>
        }
        if(textToDisplay === TEXT_TO_DISPLAY.NO_RESULT_TO_SUGGEST){
            return <p className={"informationText"}>Mmmhhh ! Pas encore de suggestions sur ce livre.<br/>Essayez-en un autre !</p>
        }
        if(textToDisplay === TEXT_TO_DISPLAY.NO_RESULT_SEARCH){
            return <p className={"informationText"}>Pas de résultat.<br/>Essayez une autre recherche !</p>
        }
        return null;
    }

    function goToPreviousResult() {
        dispatch(cleanRecommandation())
        sendLogToLeanplum(LOGS.BACK_TO_SEARCH_RESULTS)()
    }

    function getSelectedBook() {
        if(selectedBook && textToDisplay === TEXT_TO_DISPLAY.NONE) {
            const pict = !!selectedBook.picture && !!selectedBook.picture.length > 0 && selectedBook.picture[0];
            return <section className={"upperSelectedBook"}>
                <button className={"backToSearch"} onClick={goToPreviousResult}><img src={LeftArrow} />Revenir aux résultats</button>
                <div className={"selectedBook"}>
                    {
                        !!pict ?
                            <img src={`${CST.S3_WORKS}/${pict}-300`}/>
                            :
                            <DefaultCover/>
                    }
                <div>
                    <p>Vous avez aimé <span className={"titleBook"}>{selectedBook.title}</span></p>
                    <p>Voici ce que j'ai trouvé pour vous !</p>
                </div>
            </div>
            </section>;
        }
        return null;
    }

    if(loadingState === LOADING_TYPE.NONE) {
        var classNameAround = "contentAroundHorizontalResult"
        var classNameHorizontalResult = "HorizontalResult"
        if(searchResult.length > 0 && recommandationsResult.length === 0){
            classNameAround += " marginTopArrow"
        }

        if(recommandationsResult.length > 0){
            classNameHorizontalResult += " hideOverflowX"
        }

        return (
            <section className={"ResultSection"}>
                {renderTitleText()}
                { searchResult && searchResult.length > 0 && (
                    <div className={classNameAround}>
                        {searchResult.length > 0 && recommandationsResult.length === 0 && <img src={rightArrow} className={"arrowRight"} />}
                        {/*<div className="gradientLeft" />*/}
                        <div className="gradientRight" />
                        <section className={classNameHorizontalResult}>
                            {renderSearchResult()}
                        </section>
                    </div>)
                }
                <section className={"RecommandationSection"}>
                    {getSelectedBook()}
                    {renderRecommandationResult()}
                </section>
            </section>
        )
    } else if (loadingState === LOADING_TYPE.LOADING_SEARCH || loadingState === LOADING_TYPE.LOADING_RECOMMANDATIONS){
        return (<LoadingAnimation loadingState={loadingState}/>)
    }
}