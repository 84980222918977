import React from 'react';
import Branch from 'branch-sdk';
import './RecommandationResult.css';
import star from '../assets/star.svg';
import {CST, LOGS, sendLogToLeanplum} from "../utils";
import DefaultCover from "../components/DefaultCover";

export default class RecommandationResult extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            seeMore: false,
            branchLink: 'http://gleeph.net',
        }
    }

    componentDidMount() {
        var linkData = {
            id: this.props.id,
            data: {
                screen: 'WorkProfileScreen',
                id: this.props.id,
                '$og_title': this.props.title,
                '$og_description': this.props.description,
                '$og_image_url':`${CST.S3_WORKS}/${this.props.picture}-300`
            }
        };
        let self = this;
        Branch.link(linkData, function(err, link) {
            if(link){
                self.setState({
                    branchLink : link,
                })
            }
        });
    }

    expandSeeMore = () => {
        this.state.seeMore ?
            sendLogToLeanplum(LOGS.CLOSE_DESCRIPTION)() :
            sendLogToLeanplum(LOGS.OPEN_DESCRIPTION)()
        this.setState((prev)=>({
            seeMore : !prev.seeMore,
        }))
    }

    clickOnLink = () => {
        sendLogToLeanplum(LOGS.CLICK_ON_WISHLIST)()
    }

    render(){
        const { seeMore, branchLink } = this.state;
        const authors = this.props.authors instanceof Array
            ? this.props.authors.join(', ')
            : this.props.authors || '';
        return (
            <section className={"RecommandationResult shadowed"}>
                {
                    !!this.props.picture ?
                        <img src={`${CST.S3_WORKS}/${this.props.picture}-300`} className={"shadowed"}/>
                        :
                        <DefaultCover/>
                }
                    <section className={"sectionInfos"}>
                        <h2 className={"title"}>{this.props.title}</h2>
                        <h3 className={"author"}>{authors} • {this.props.publicationDate}</h3>
                        <h3 className={"edition"}>{this.props.edition}</h3>
                    </section>
                    <section className={"sectionResume"} onClick={this.expandSeeMore}>
                        <p className={seeMore ? "seeMoreResume" : ""}>{this.props.description}</p>
                        <p className={"seeMore"}>{seeMore ? "Lire moins" : "Lire plus"}</p>
                    </section>
                    <a href={branchLink} target="_blank" onClick={this.clickOnLink}><div className={"button"}><img src={star}/>Mettre en wishlist</div></a>
            </section>
        )
    }
}