import React from 'react';
import './Header.css';
import logoTwitter from '../assets/twitter.svg';
import logoFacebook from '../assets/facebook.svg';
import logoGleeph from '../assets/Gleeph.svg';
import logoLinkedIn from '../assets/LinkedIn.svg';
import {useDispatch, useSelector} from "react-redux";
import {
    LOADING_TYPE,
    selectLoadingState,
    selectRecommandationsResult,
    selectSearchResult,
    resetNavigation,
} from "../features/searchSlice";
import {LOGS, sendLogToLeanplum} from "../utils";

export default function Header(props) {
    const dispatch = useDispatch();
    const searchResult = useSelector(selectSearchResult);
    const recommandationsResult = useSelector(selectRecommandationsResult);
    const loadingState = useSelector(selectLoadingState);

    let className = 'GleephMainLogo';
    let classNameH1 = 'translateYTitle';

    if(loadingState !== LOADING_TYPE.NONE || searchResult.length > 0 || recommandationsResult.length > 0) {
        className += ' upperPositionTransformLogo';
        classNameH1 += ' hideTitleTransform';
    } else {
        className += ' defaultTransformY';
    }

    function resetNav() {
        dispatch(resetNavigation())
    }

    return (
        <header className={"Header"}>
            <div className={className}>
                <h1 className={classNameH1}>Le moteur de suggestion de lecture, par</h1>
                <img src={logoGleeph} onClick={resetNav} className="GleephMainLogoSvg button" alt="logo gleeph" />
            </div>
            <div className={"SocialShare"}>
                <a href={"http://twitter.com/share?text=Suggestion-de-livres-par-gleeph&url=https://suggestion.gleeph.net/&hashtags=gleeph"} onClick={sendLogToLeanplum(LOGS.CLICK_SOCIAL_BUTTON, {social: 'TWITTER'})} className="Header-logo Header-logo-twitter" target="_blank"><img src={logoTwitter} className="button" alt="logo twitter" /></a>
                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://suggestion.gleeph.net"} onClick={sendLogToLeanplum(LOGS.CLICK_SOCIAL_BUTTON, {social: 'FB'})} className="Header-logo Header-logo-facebook" target="_blank"><img src={logoFacebook} className="button" alt="logo facebook" /></a>
                <a href={"https://www.linkedin.com/sharing/share-offsite/?url=https://suggestion.gleeph.net"} onClick={sendLogToLeanplum(LOGS.CLICK_SOCIAL_BUTTON, {social: 'LINKEDIN'})} className="Header-logo Header-logo-LinkedIn" target="_blank"><img src={logoLinkedIn} className="button" alt="logo LinkedIn"/></a>
            </div>
        </header>
    )
}