import Leanplum from "leanplum-sdk";

var librariesInit = false;

export const CST = {
    BASE_URL: 'https://public.gleeph.net',
    S3_WORKS: 'https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works',
};

export const LOGS = {
    LAUNCH: 'LAUNCH',
    CLICK_SOCIAL_BUTTON: 'CLICK_SOCIAL_BUTTON',
    CLICK_STORE_MIN_BUTTON: 'CLICK_STORE_MIN_BUTTON',
    CLICK_STORE_FULL_BUTTON: 'CLICK_STORE_FULL_BUTTON',
    CLICK_GLEEPH_BUTTON: 'CLICK_GLEEPH_BUTTON',
    FOCUS_SEARCH_TEXT: 'FOCUS_SEARCH_TEXT',
    CLICK_ON_DO_SEARCH: 'CLICK_ON_DO_SEARCH',
    SELECT_BOOK_FROM_SEARCH: 'SELECT_BOOK_FROM_SEARCH',
    BACK_TO_SEARCH_RESULTS: 'BACK_TO_SEARCH_RESULTS',
    OPEN_DESCRIPTION: 'OPEN_DESCRIPTION',
    CLOSE_DESCRIPTION: 'CLOSE_DESCRIPTION',
    CLICK_ON_WISHLIST: 'CLICK_ON_WISHLIST',
    CLICK_ON_CLEAR_SEARCH: 'CLICK_ON_CLEAR_SEARCH',
}

export function sendLogToLeanplum(log, opt){
    if(librariesInit){
        return function() {
            Leanplum.track(log, opt)
        }
    }
    return function(){}
}

export function setLibrariesStatus(status){
    librariesInit = true;
}
