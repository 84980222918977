import React from 'react';
import './App.css';
import Header from './components/Header';
import CoreContent from "./components/CoreContent";
import Footer from './components/Footer';
import branch from "branch-sdk";
import Leanplum from "leanplum-sdk";
import {LOGS, sendLogToLeanplum, setLibrariesStatus} from "./utils";

export default class App extends React.Component {
    constructor(){
        super()
        var lastValue = localStorage.getItem('displayCookie')
        var displayCookie = lastValue == null ? true : false
        if(displayCookie === false){
            this.initializeLogsAfterCookieValidation()
        }
        this.state = {
            displayCookie : displayCookie,
        }
    }

    initializeLogsAfterCookieValidation=()=>{
        branch.init('key_live_eeCJrX2lundNKyCTOlHSllaeCxcujJkC', function(err, data) {
        });
        // This value should be set to true only if you're developing on your server.
        var isDevelopmentMode = process.env.NODE_ENV === 'development';
        if (isDevelopmentMode) {
            Leanplum.setAppIdForDevelopmentMode("app_ASfngxR8yeW0cs2FyxshgWucAFMXRzM6F5hXWPX8zXs", "dev_E51qxno2zTYdvjnh6H5xZ4QlX0KucxdOIOsreduaE40");
        } else {
            Leanplum.setAppIdForProductionMode("app_ASfngxR8yeW0cs2FyxshgWucAFMXRzM6F5hXWPX8zXs", "prod_PEkWB4t82badGF2RfrTn18G8pDaAN5t5GgPPxyahM2A");
        }

        Leanplum.start(function(success) {
        });
        setLibrariesStatus(true);
        sendLogToLeanplum(LOGS.LAUNCH)()
    }

    validateCookie=()=>{
        localStorage.setItem('displayCookie', Date.now())
        this.initializeLogsAfterCookieValidation()
        this.setState({
            displayCookie: false,
        })
    }

    refuseCookie = () => {
        this.setState({
            displayCookie: false,
        })
    }

  render() {
    const className = `App ${this.state.displayCookie && "backgroundBlur"}`
    return (
        <div>
            {
                this.state.displayCookie && (<div className={"behindModal"}>
                    <div className={"cookieModal shadowed"}>
                        <div >
                            <div>
                                <p>Ce site utilise des Cookies pour vous proposer une navigation optimale et nous permettre de réaliser des statistiques de visites.
                                    <br/><br/>Pour donner votre consentement au dépôt des cookies de mesure d’audience, cliquez sur Accéder au site. <br/><br/>Pour plus d’informations <a href={"https://gleeph.net/legal.html"}>cliquez ici.</a></p>
                                </div>
                        </div>
                        <button type={"button"} className={"desktopButton button"} onClick={this.validateCookie}>Accéder au site</button>
                        <button type={"button"} className={"minButton button"} onClick={this.refuseCookie}>Décliner</button>
                    </div>
                </div>)
            }
            <div className={className}>
                <div>
                    <Header />
                    <CoreContent />
                </div>
                <Footer/>
            </div>
        </div>
    );
  }
}
