import React from 'react';
import Lottie from 'lottie-web';
import LoadingAnimationJSON from '../assets/LottieAnimations/LOADING_ANIMATION_BLACK';

export default class LoadingAnimation extends React.Component {
    componentDidMount() {
        Lottie.loadAnimation({
            container: document.getElementById('loadingAnimation'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: LoadingAnimationJSON,
        })
    }

    render() {
        return (
            <div id={"loadingAnimation"}>
            </div>
        )
    }
}