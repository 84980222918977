import React, {useState} from 'react';
import logoLoupe from "../assets/loupe.svg";
import logoCross from "../assets/cross.svg";
import './FormSection.css';
import {fetchSearchResult, resetNavigation, selectTextToDisplay, TEXT_TO_DISPLAY} from "../features/searchSlice";
import {useDispatch, useSelector} from "react-redux";
import {LOGS, sendLogToLeanplum} from "../utils";

export default function FormSection(props) {
    const [ searchValue, setSearchValue ] = useState('');
    const textToDisplay = useSelector(selectTextToDisplay);

    const dispatch = useDispatch();
    function handleSubmit(event) {
        event.preventDefault();
        document.getElementById('searchValue').blur()
        dispatch(fetchSearchResult(searchValue))
        sendLogToLeanplum(LOGS.CLICK_ON_DO_SEARCH)()
    }

    function setNewSearchValue(e){
        setSearchValue(e.target.value);
    }

    function resetSearch(){
        setNewSearchValue({target:{value:""}});
        dispatch(resetNavigation());
        sendLogToLeanplum(LOGS.CLICK_ON_CLEAR_SEARCH)()
    }

    return (
        <section className={"FormSection"}>
            <form onSubmit={handleSubmit}>
                <div className={"shadowed"}>
                    <img src={logoLoupe} className="FormSectionLogoLoupe" alt="logo loupe" />
                    <input onChange={setNewSearchValue} onFocus={sendLogToLeanplum(LOGS.FOCUS_SEARCH_TEXT)} value={searchValue} type="text" name="searchValue" id="searchValue" required placeholder={"Saisissez ici un auteur, un titre ou un ISBN"}/>
                    { textToDisplay === TEXT_TO_DISPLAY.SEARCH_A_BOOK && <input type={"submit"} value={"C'est parti !"} className={"desktopButton button"}/>}
                    { textToDisplay !== TEXT_TO_DISPLAY.SEARCH_A_BOOK && <img src={logoCross} onClick={resetSearch} className={"FormSectionLogoCross button"} alt={"logo croix"} />}
                </div>
                { textToDisplay === TEXT_TO_DISPLAY.SEARCH_A_BOOK && <input type={"submit"} value={"C'est parti !"} className={"mobileButton button"}/>}
            </form>
        </section>
    );
}