import { createSlice } from '@reduxjs/toolkit';
import { CST } from '../utils'

export const LOADING_TYPE = {
    NONE : "NONE",
    LOADING_SEARCH : "LOADING_SEARCH",
    LOADING_RECOMMANDATIONS : "LOADING_RECOMMANDATIONS",
}

export const TEXT_TO_DISPLAY = {
    SEARCH_A_BOOK: "SEARCH_A_BOOK",
    SELECT_A_BOOK: "SELECT_A_BOOK",
    NO_RESULT_SEARCH: "NO_RESULT_SEARCH",
    NO_RESULT_TO_SUGGEST: "NO_RESULT_TO_SUGGEST",
    NONE: "NONE",
    ERROR: "ERROR",
};

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        searchResult: [],
        recommandationsResult: [],
        selectedBook: null,
        loading: LOADING_TYPE.NONE,
        textToDisplay:TEXT_TO_DISPLAY.SEARCH_A_BOOK,
    },
    reducers: {
        fetchSearchPending:(state, action) => {
            state.recommandationsResult = [];
            state.loading= LOADING_TYPE.LOADING_SEARCH;
            state.selectedBook = null;
        },
        fetchSearchResultSuccess:(state, action) => {
            state.searchResult = action.payload;
            state.loading = LOADING_TYPE.NONE;
            if(action.payload.length === 0) {
                state.textToDisplay = TEXT_TO_DISPLAY.NO_RESULT_SEARCH;
            } else {
                state.textToDisplay = TEXT_TO_DISPLAY.SELECT_A_BOOK;
            }
        },
        fetchSearchResultError:(state, action) => {

        },
        fetchRecommandationPending:(state, action) => {
            state.loading= LOADING_TYPE.LOADING_RECOMMANDATIONS;
            state.selectedBook = action.payload;
            state.textToDisplay = TEXT_TO_DISPLAY.NONE;
        },
        fetchRecommandationResultSuccess:(state, action) => {
            state.recommandationsResult = action.payload.slice(0);
            state.loading = LOADING_TYPE.NONE;
            if(action.payload.length === 0){
                state.textToDisplay = TEXT_TO_DISPLAY.NO_RESULT_TO_SUGGEST;
            } else {
                state.textToDisplay = TEXT_TO_DISPLAY.NONE;
            }
        },
        fetchRecommandationResultError:(state, action) => {

        },
        resetNavigation:(state, action) => {
            state.searchResult= [];
            state.recommandationsResult= [];
            state.selectedBook= null;
            state.loading= LOADING_TYPE.NONE;
            state.textToDisplay= TEXT_TO_DISPLAY.SEARCH_A_BOOK;
        },
        cleanRecommandation:(state, action)=>{
            state.recommandationsResult= []
            state.loading = LOADING_TYPE.NONE;
            state.textToDisplay = TEXT_TO_DISPLAY.SELECT_A_BOOK;
        },
    },
});

export const { fetchSearchPending, fetchSearchResultSuccess, fetchSearchResultError,
                fetchRecommandationPending, fetchRecommandationResultSuccess, fetchRecommandationResultError,
                resetNavigation, cleanRecommandation } = searchSlice.actions;

export const fetchSearchResult = searchValue => dispatch => {
    dispatch(fetchSearchPending());
    fetch(`${CST.BASE_URL}/fareinheit/search`, {
        method: 'POST',
        body: JSON.stringify({query: searchValue}),
        headers: new Headers({
            "x-api-key" : "848d257a-78bc-11ea-bc55-0242ac130003",
            "Content-Type": "application/json"
        })
    })
        .then( res => {
            res.json().then((resultParse)=>
            dispatch(fetchSearchResultSuccess(resultParse)))
        })
        .catch(error => {
            console.error("ERROR", error)
            dispatch(fetchSearchResultError(error));
        })
}

export const fetchRecommandationResult = bookValue => dispatch => {
    dispatch(fetchRecommandationPending(bookValue));
    fetch(`${CST.BASE_URL}/fareinheit/book/similar/${bookValue.id}`, {
        method: 'GET',
        headers: new Headers({
            "x-api-key" : "848d257a-78bc-11ea-bc55-0242ac130003",
            "Content-Type": "application/json"
        })
    }).then(response => response.json())
        .then(data => {
            dispatch(fetchRecommandationResultSuccess(data));
        })
        .catch(error => {
            dispatch(fetchRecommandationResultError(error));
        })
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSearchResult = state => state.search.searchResult;
export const selectRecommandationsResult = state => state.search.recommandationsResult;
export const selectLoadingState = state => state.search.loading;
export const selectSelectedBook = state => state.search.selectedBook;
export const selectTextToDisplay = state => state.search.textToDisplay;

export default searchSlice.reducer;
